.hhh {
    border: 1px solid lightgrey !important;
    padding: 5px !important;
    border-radius: 10px !important;
    color: black;
    margin: 8px 0;
}
.hhh:hover{
    box-shadow: 0 0 5px lightgray;
}

.store-container{
    width: 100%;
    padding: 10px 5px;   
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    transition: .3s ease-in-out;
    gap: 5px;
}
.store-container:hover{
    cursor: pointer;
    border-radius: 5px;
}
.store-img{
    height: 60px;
    min-width: 60px !important;
    display: flex;
    justify-content: center !important;
    border-radius: 50%;
    align-items: center !important;
}
.store-img img {
    aspect-ratio: 1 / 1;
    scale: 1 !important;
    border-radius: 50% !important;
}

.store-detail {
    margin-left: 5px;
}
.store-detail p{
    line-height: 20px !important;
}

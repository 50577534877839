.v-form-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .v-form-input > * {
    min-height: 3rem;
    padding: 4px 1.5rem;
  }
  .v-form-input > *:focus, .v-form-input > *:active {
    box-shadow: none;
  }
  .v-add {
    font-size: 0.9rem;
  }
  .v-delete svg {
    --size: 1.3rem;
    height: var(--size);
    width: var(--size);
  }
  .category-container {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
  .v-num {
    font-weight: bold;
  }
  .v-list-of-options {
    flex-wrap: wrap;
    gap: 10px;
  }
  .v-list-of-options .v-option {
    padding: 10px 12px;
    border: 1.5px dashed #dedede;
    width: max-content;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
  }
  .v-list-of-options .v-option:has(input[type="checkbox"]:checked) {
    background-color: #a2c9ff;
    border-color: #0000ff;
  }
  .v-list-of-options .v-text-name {
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .v-list-of-options .v-add-new {
    font-size: 0.9rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 10px;
    white-space: nowrap;
  }
  .v-image-container {
    width: 3rem;
    height: auto;
    aspect-ratio: 1.7777777778;
  }
  #addNewProduct form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  #addNewProduct .v-form-input {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }
  #addNewProduct .v-form-input > * {
    min-height: auto;
  }
  #addNewProduct .v-form-input > .form-label {
    padding: 0;
  }
  #addNewProduct .v-form-input > .form-control {
    padding-inline: 10px;
  }
  #addNewProduct .v-form-input > .form-control:not(input[type="file"]) {
    min-height: 2.8rem;
  }
  #addNewProduct .v-custom-file-input {
    min-height: 120px;
    border: 1.5px #010101;
    border-radius: 5px;
    width: 100%;
  }
  

:root {
	--main-rgb: 27, 188, 155;
	--primary-clr: #1bbc9b;
	--primary-clr-deep: #17a185;
	--white: #fff;
}


a {
	will-change: background-color, color;
	transition: 300ms ease-in-out;
	transition-property: background-color;
	text-decoration: none;
}
button {
	background-color: transparent;
	border: none;
}
:is(h1, h2, h3, h4, h5, h6) {
	margin: 0;
	color: var(--dark, #000);
}

.v-body-container .v-h-parent-grid {
	height: 100dvh;
	height: 100dvh;
	height: 100vh;
	min-height: 100dvh;
	display: grid;
	grid-template-columns: 1fr;
}
.v-main-brands-board,
.v-aside-inner {
	padding: 1rem;
}
.v-aside-inner ul {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	column-gap: 5px;
	row-gap: 5px;
}
.v-aside-inner ul li {
	flex-grow: 1;
}
.v-aside-inner ul a {
	padding: 0.6rem 0.8rem;
	justify-content: center;
	border-radius: 6px;
	display: flex;
	align-items: center;
	column-gap: 10px;
	width: 100%;
	border: 1px solid rgba(0 0 0 / 10%);
}
.v-aside-inner ul a:hover,.v-aside-inner ul a.active {
	background-color: rgba(var(--main-rgb), 0.09);
	border-color: rgba(var(--main-rgb), 0.3);
}
.v-aside-inner ul a:hover svg,.v-aside-inner ul a.active svg {
	color: var(--primary-clr);
}
.v-aside-inner ul a svg {
	color: rgb(53 53 53 / 80%);
}
.v-aside-inner ul a .v-link-text {
	color: rgba(0 0 0 / 60%);
}
.v-main-brands-board .v-create-new-store {
	background-color: var(--primary-clr-deep);
	color: var(--white);
	font-weight: 500;
	font-size: 0.9rem;
	border-radius: 5px;
	padding: 0.7rem 1.1rem;
}
.v-main-brands-board .v-create-new-store:hover {
	background-color: var(--primary-clr);
}
.v-main-brands-board .v-grid-container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
}
.v-main-brands-board .v-grid-container .v-brand {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	column-gap: 10px;
	row-gap: 14px;
	border: 1px solid rgb(236 236 236);
	background-color: rgb(248, 248, 248);
	padding: 0.8rem;
	border-radius: 0.6rem;
}
@media all and (min-width: 500px) {
	.v-main-brands-board .v-grid-container .v-brand {
		flex-direction: row;
	}
}
@media all and (min-width: 768px) {
	.v-aside-inner ul {
		flex-direction: column;
	}
	.v-aside-inner ul li {
		width: 100%;
		justify-content: start;
	}
	.v-aside-inner ul a {
		border: 0;
		justify-content: flex-start;
	}
	.v-aside {
		border-right: 1px solid rgba(0 0 0 / 10%);
	}
	.v-body-container .v-h-parent-grid {
		grid-template-columns: 1fr 4fr;
	}
	.v-main-brands-board .v-grid-container {
		grid-template-columns: 1fr 1fr;
	}
}

.v-brand .v-brand-img-container {
	border-radius: 50%;
	overflow: hidden;
	width: 100%;
	min-width: 40px;
	max-width: 50px;
	background-color: var(--white);
	border: 1px solid rgba(0 0 0 / 5%);
	display: flex;
	align-items: center;
	justify-content: center;
}
.v-brand .v-brand-img-container .img-fluid {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.v-brand .v-brand-detail {
	flex-grow: 1;
	display: flex;
	width: 100%;
	flex-direction: column;
	row-gap: 6px;
}
.v-brand .v-brand-detail .v-brand-name {
	font-weight: 530;
	font-size: 1.1rem;
}
.v-brand .v-brand-detail .v-brand-description {
	font-size: 1.01rem;
	opacity: 0.95rem;
}
.v-brand .v-brand-detail-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.v-brand .v-brand-detail-footer .v-curr-sub {
	font-size: 0.8rem;
	opacity: 0.8;
	font-weight: 600;
	letter-spacing: 0.3px;
	background-color: rgb(188 188 188 / 80%);
	border: 1px solid rgb(241, 240, 240, 1);
	border-radius: 1000px;
	padding: 0.3rem 1rem;
}

@font-face {
	font-family: SpaceGrotesk;
	src: url("../fonts/SpaceGrotesk.ttf");
}

:root {
	--primary-clr-variant: 27, 187, 155;
	--primary-clr: rgba(var(--primary-clr-variant), 1);
	--primary-clr-deep: #17a185;
	--white: #fff;
    --font-family: SpaceGrotesk, "Helvetica", "Arial", "system-ui";
}

 h1,
h2,
h3,
h4,
h5,
h6,
p {
	image-rendering: pixelated;
	text-rendering: geometricPrecision;
}
ul,
li,
p {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
	will-change: background-color, color;
	transition: 300ms ease-in-out;
	transition-property: background-color;
	text-decoration: none;
}
button {
	background-color: transparent;
	border: none;
}
:is(h1, h2, h3, h4, h5, h6) {
	margin: 0;
}

/* svg {
	--size: 1.3rem;
	width: var(--size);
	height: var(--size);
	user-select: none;
	aspect-ratio: 1/1;
	pointer-events: none;
}  */

.v-general-inner-container {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding-block: 2.8rem;
}

.v-accordion-container .accordion-button .v-quest-text-container {
	font-size: 1.01rem;
	font-weight: 500;
}
.v-accordion-container .accordion-button {
	gap: 0.5rem;
}
.v-accordion-container .accordion-button:focus {
	border-color: var(--primary-clr);
	box-shadow: 0 0 0 0.25rem rgba(var(--primary-clr-variant), 0.1);
}
.v-accordion-container .accordion-button:not(.collapsed) {
	color: var(--primary-clr);
	background-color: rgba(var(--primary-clr-variant), 0.2);
}
.v-accordion-container .v-ans-text-container {
	font-size: 0.98rem;
	opacity: 0.8;
}

.v-accordion-container .v-ans-text-container .v-link-out {
	font-weight: 500;
	color: var(--primary-clr-deep);
	letter-spacing: 0.3px;
	text-decoration: underline;
}


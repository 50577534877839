 @font-face {
	font-family: SpaceGrotesk;
	src: url("../fonts/SpaceGrotesk.ttf");
}

:root {
	--primary-clr-variant: 27, 187, 155;
	--primary-clr: rgba(var(--primary-clr-variant), 1);
	--primary-clr-deep: #17a185;
	--white-variant: 255, 255, 255;
	--white: rgba(var(--white-variant), 1);
	--font-family: SpaceGrotesk, "Helvetica", "Arial", "system-ui";
} 

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
p,
li,
a,
button {
	font-family: var(--font-family);
}
header.v-main-header{
	background: none;
	position: initial !important;
}
header.v-card-header{
	background: none;
	position: initial;
}
ul,
li,
p {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
	will-change: background-color, color;
	transition: 300ms ease-in-out;
	transition-property: background-color;
	text-decoration: none;
}
button {
	background-color: transparent;
	border: none;
}
:is(h1, h2, h3, h4, h5, h6) {
	margin: 0;
	line-height: 1;
}


.v-wrap {
	padding-block: 3rem;
}
.v-wrap .v-wrap-inner {
	display: flex;
	flex-direction: column;
	row-gap: 4rem;
}
.v-parent-plan-container .v-parent-container-title {
	position: relative;
}
.v-parent-plan-container .v-parent-container-title::before {
	background-color: var(--primary-clr);
	height: 3px;
	width: 75%;
	content: "";
	position: absolute;
	display: none;
	bottom: -4px;
	left: 0;
}
.v-each-4-cards-container {
	gap: 1.5rem;
	margin-top: 1.5rem;
	display: grid;
}
.v-each-main-card .v-card-header {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-block: 1.5rem 2rem;
	row-gap: 1.5rem;
	border-bottom: 1px solid rgba(100, 100, 111, 0.2);
	margin-bottom: 2.3rem;
}
.v-each-main-card .v-card-header .v-card-type {
	text-transform: uppercase;
	font-size: 1.01rem;
}
.v-each-main-card .v-card-header .v-card-price {
	display: flex;
	align-items: flex-end;
}
.v-each-main-card.v-best-plan .v-card-header {
	border-color: rgba(var(--white-variant), 0.5);
}
.v-each-main-card.v-best-plan .v-card-header {
	color: var(--white);
}
.v-each-4-cards-container .v-each-main-card {
	border-radius: 1rem;
	height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	background-color: var(--white);
	display: flex;
	align-items: stretch;
	position: relative;
	overflow: hidden;
}

@media all and (min-width: 640px) {
	.v-parent-plan-container .v-parent-container-title::before {
		display: block;
	}
}
@media all and (min-width: 700px) {
	.v-each-4-cards-container {
		grid-template-columns: 1fr 1fr;
	}
}
@media all and (min-width: 991px) {
	.v-each-4-cards-container {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.v-each-4-cards-container .v-each-main-card.v-best-plan .v-tag {
	position: absolute;
	top: 0;
	left: 8%;
	background-color: var(--white);
	padding: 6px 10px;
	max-width: max-content;
	width: 100%;
	max-height: 40px;
	border-radius: 0 0 10px 10px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	color: var(--primary-clr-deep);
	font-size: 0.95rem;
	transition: max-height 200ms ease-in-out;
	height: 100%;
	white-space: nowrap;
}
.v-each-4-cards-container .v-each-main-card.v-best-plan:hover .v-tag {
	max-height: 50px;
}
.v-each-4-cards-container .v-each-main-card-inner {
	padding: 1.8rem 1.8rem 2.4rem;
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.v-each-4-cards-container .v-each-main-card-inner .v-card-body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	justify-content: space-between;
}
.v-each-main-card-inner .v-card-body .v-listed-goodies {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}
.v-each-main-card .v-cta-container .v-cta {
	background-color: var(--primary-clr);
	color: var(--white);
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-block: 13px;
	border-radius: 4px;
	transition: background-color 300ms ease-in-out;
}
.v-each-main-card.v-best-plan .v-cta-container .v-cta {
	background-color: var(--white);
	color: var(--primary-clr);
}
.v-each-main-card-inner .v-listed-goodies .v-each-goodie {
	display: flex;
	align-items: flex-start;
	column-gap: 10px;
}
.v-each-main-card-inner .v-listed-goodies .v-each-goodie .v-goodie {
	font-size: 0.96rem;
	line-height: 1.4;
	opacity: 0.9;
}
.v-each-main-card-inner .v-each-goodie .v-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}
.v-each-main-card-inner .v-each-goodie .v-icon svg {
	color: var(--primary-clr);
	fill: var(--primary-clr);
	--size: 1.2rem;
}
.v-each-4-cards-container .v-each-main-card.v-best-plan {
	background-color: var(--primary-clr);
}
.v-each-main-card.v-best-plan .v-icon svg {
	color: var(--white);
	fill: var(--white);
}
.v-each-main-card.v-best-plan .v-listed-goodies .v-each-goodie .v-goodie {
	color: var(--white);
}

.t-btn{
    background-color: #1BBC9B;
    color: white;
    font-weight: bold;
}
.t-l-btn{
    background-color: #1BBC9B;
    color: white;
}
.t-btn2{
    background-color: #2C3E50;
    color: white;
}
.t-arrow-icon{
    background-color: #2C3E50;
    color: inherit;
    border-radius: 60%;
}
.t-label{
    font-weight: 550;
    font-size: medium;
    margin-bottom: 2px;
    margin-top: 4px;
}

.card-box .card-drop {
  color: #9a9da0;
  font-size: 20px;
  line-height: 1px;
  padding: 0px 5px;
  display: inline-block;
}
.text-success {
}
.text-muted {
    color: #9a9da0 !important;
}
.logo-alt-box .logo {
    
}
.logo-alt-box h5 {
    
}

/* test @::start */

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	outline: none;
	image-rendering: optimizeQuality;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span:not(:has(img, .img-fluid)) {
	image-rendering: pixelated;
	text-rendering: geometricPrecision;
}
ul,
li,
p {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
	will-change: background-color, color;
	transition: 300ms ease-in-out;
	transition-property: background-color;
	text-decoration: none;
}
button {
	background-color: transparent;
	border: none;
}
:is(h1, h2, h3, h4, h5, h6) {
	margin: 0;
	color: var(--dark);
}
.v-container {
	border-radius: 6px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 6px;
	overflow: hidden;
}
.v-container .v-container-inner {
	position: relative;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	row-gap: 1rem;
	padding: 3.2rem 1rem;
}
.v-container .v-container-inner::before {
	position: absolute;
	padding-block: 4px;
	top: 0;
	right: 0;
	content: "";
	width: 100%;
	left: 0;
}
.v-container.v-error .v-container-inner::before {
	background-color: rgb(247, 3, 11);
}
.v-container.v-success .v-container-inner::before {
	background-color: rgb(42, 205, 115);
}
.v-container .v-container-inner .v-logo-container {
	max-width: 10rem;
	margin-block: 1rem;
}
.v-container .v-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
	border-radius: 50%;
	aspect-ratio: 1/1;
}
.v-container.v-error .v-icon-container {
	background-color: rgb(247, 3, 11, 0.1);
	color: rgb(247, 3, 11);
}
.v-container.v-success .v-icon-container {
	background-color: rgb(42, 205, 115, 0.1);
	color: rgb(42, 205, 115);
	fill: rgb(42, 205, 115);
}
.v-container .v-icon-container svg {
	width: 2.5rem;
	height: 2.5rem;
}
.v-container .v-ctas {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}
.v-container .v-text-container-title {
	font-weight: 500;
}
.v-container .v-text-container {
	display: flex;
	flex-direction: column;
	row-gap: 0.6rem;
	margin-top: 10px;
}
.v-container .v-ctas .v-each-cta {
	flex-grow: 1;
	padding: 10px 1.55rem;
	font-weight: 500;
	font-size: 0.9rem;
	background-color: var(--primary-clr);
	color: var(--white);
	border-radius: 5px;
}
.v-container .v-text-container .v-close-to-icon {
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.2px;
}
/* test @::end */

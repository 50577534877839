.productContainer{
    margin-top: 80px !important;
}
.ql-container {
    min-height: 100px !important;
}

@media all and (max-width:768px){
    .productContainer{
        margin-top: 140px !important;
    }
    .collections{
        margin-top: 100px !important;
    }
}
.menu-aside.cusTom {
    width: 100% !important;
}
.menu-aside.cusTom > li {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 5px !important;
    float: none !important;
    display: block !important;
    margin: 5px 10px !important;
}